<!--  -->
<template>
  <div class="goodsRightWrap">
    <div class="title-card">排序</div>
    <div class="goods-item" v-for="(sort, index) in sortList" :key="index">
      <div>{{index+1}}.{{sort.data.catalogName}}</div>
      <div class="item">
        <!--第一项不显示up图标-->
        <div v-if="index!=0" @click="sortUp(index)">
          <i class="el-icon-upload2"></i>
        </div>
          <!--最后一项不显示down图标-->
        <!-- <div v-if="index!=sortList.length-1" @click="sortDown(index)">
          <i class="el-icon-download"></i>
        </div> -->
      </div>
    </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      sortList: []
    };
  },
  mounted(){
    this.getListCatalogType();
  },
  methods: {
    //获取商品结构
    getListCatalogType(){
        this.$API.getListCatalogType().then(res => {
            console.log(res.data);
            if(res.code === 0){
                this.sortList = res.data;
            }
        })
    },
    sortUp(index) {
      let oldList = [...this.sortList];
      let newList = [...this.sortList];
      // 将当前数组下标的为1的item删除
      newList.splice(index, 1);
      // 在当前数组下标的前一个数组下标添加 当前数组下标的item
      newList.splice(index-1, 0, oldList[index] );
      this.sortList = [...newList];
    },
    // sortDown (index) {
    //   let oldList = [...this.sortList];
    //   let newList = [...this.sortList];
    //   newList.splice(index, 1);
    //   newList.splice(index+1, 0, oldList[index] );
    //   this.sortList = [...newList]	  
    // },
  }
}

</script>
<style lang='scss' scoped>
.goodsRightWrap{
  .title-card{
    border-bottom: 1px solid #eee;
    margin: 15px 10px;
    padding-bottom: 10px;
  }
  .goods-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #eee;
    margin: 5px 10px;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
  }
  .item{
    display: flex;
    flex-flow: wrap;
  }
}
</style>