<!-- 店铺-商品 -->
<template>
  <div class="store-case-wrap">
    <!-- 主体 -->
    <div class="box-card">
      <div class="card-title">
          <div class="title-hoem"></div>
          <div class="title-btn">
              <!-- <el-button size="small">预览效果</el-button> -->
              <div class="title">商品装修</div>
              <el-button size="small" type="primary" @click="saveIndexMini">保存</el-button>
          </div>
      </div>
      <div class="content">
        <div class="left-wrap">
          <goods-left ref="goodsLeft" @clickItem="clickItem"></goods-left>
        </div>
        <div class="right-wrap">
          <goods-right ref="homeRight"></goods-right>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import goodsLeft from "./components/goodsLeft";
import goodsRight from "./components/goodsRight";
import pageMixin from '@/internal/pageMixin'
export default {
  mixins:[pageMixin],
  components: {
    goodsLeft,
    goodsRight,
  },
  data() {
    return {
      pageMixin_index:'sGoodsDecoration',
    };
  },
  methods: {
    saveIndexMini(){},
    clickItem(index) {
      this.$nextTick(() => {
        this.$refs.caseLeft.setValue(index);
      });
    },
  },
};
</script>
<style lang='scss' >
.store-case-wrap {
  height: 100%;
  overflow: hidden;
  .box-card {
    margin: 0 20px;
    .card-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        .title-hoem{
            flex: 3;
        }
        .title-btn{
            flex: 1;
            background: #fff;
            padding: 10px 0;
            margin: 5px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .el-button{
                margin: 0 20px;
                width: 70px;
            }
            .title{
                margin: 0 20px;
                font-size: 16px;
            }
        }
    }
    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      height: calc(100vh - 115px);
      .left-wrap {
        flex: 3;
        height: 100%;
      }
      .right-wrap {
        flex: 1;
        background: #fff;
        height: 100%;
        overflow: hidden;
        // .el-upload {
        //   width: 100% !important;
        // }
        .el-input__inner {
          height: 30px !important;
          font-size: 12px;
        }
        .el-upload-list__item {
          transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
        }
        .el-list-enter-active,
        .el-list-leave-active {
          transition: all 0.2s;
        }
      }
    }
  }
}
</style>