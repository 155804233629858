<template>
  <div class="case-left-wrap">
    <div class="content-wrap" >
        <!-- 列表 -->
		<div class="list-data-wrap">
			<div class="list-left">
				<div class='scroll-wrap'>
                    <el-collapse v-model="activeNames" >
                        <el-collapse-item v-for="(item, index) in dataList" :key="index" :name="index">
                            <template slot="title" >
                                <div @click="clickBigTitle(item)">
                                    {{item.data.catalogName}}
                                </div>
                            </template>
                            <div v-for="(sm, index1) in item.childrenNode" :key="index1" class="sm-title">
                                <div @click="setId(sm)" :class="isActive === sm.data.id? 'active':''">{{sm.data.catalogName}}</div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>

				</div>
			</div>
			<div class="list-right">
				<div class="scroll-wrap">
                    <div class="title right_title">{{goodTitle}}</div>
					<div class="right-content">
                        <div class="right-item" v-for="(item, index) in list" :key="index">
                            <el-image class="item-img" :src="item.masterImageUrl" ></el-image>
                            <div class="item-name">{{item.goodsName}}</div>
                        </div>
					</div>
				</div>
			</div>
		</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        dataList: [],
        isPoster: 0,
        activeNames: [0],
        list: [],
        isActive: null, 
        catalogId: null,
        goodTitle: null
    };
  },
  mounted(){
      this.getListCatalogType();
    //   this.getGoodsByShopList();
  },
    methods: {
        //获取商品列表
        getGoodsByShopList(){
            console.log(this.catalogId);
            let params ={
                catalogId: this.catalogId
            }
            this.$API.getGoodsByShopList(params).then(res => {
                if(res.code === 0){
                    this.list = res.data.list;
                }
            })
        },
        //获取商品结构
        getListCatalogType(){
            this.$API.getListCatalogType().then(res => {
                console.log("sss",res.data);
                if(res.code === 0){
                    this.dataList = res.data;
                    if(res.data.length > 0){
                        this.catalogId = res.data[0].childrenNode[0].data.id;
                        this.goodTitle = res.data[0].childrenNode[0].data.catalogName;
                    }
                    this.getGoodsByShopList()
                }
            })
        },
        //点击一级对应商品
        clickBigTitle(item){
            this.goodTitle = item.data.catalogName;
            this.catalogId = item.data.id;
            this.getGoodsByShopList();
        },
        // 点击二级对应商品
        setId(item) {
            console.log(item);
            this.goodTitle = item.data.catalogName;
            this.catalogId = item.data.id;
            this.getGoodsByShopList();
        },
        posterDetail(index) {
            this.isPoster = index;
        },
    },
};
</script>
<style lang="scss">
.case-left-wrap {
  width: 370px;
  height: 90%;
  background: #fff;
  margin: 0px auto;
  box-shadow: 0 0px 1px 1px rgba(100, 100, 100, 0.1);
  .content-wrap {
    height: 100%;
    .list-data-wrap{
        display: flex;
        justify-content: space-between;
        .list-left{
            width: 90px;
            border-right: 1px solid #eee;
            text-align: center;
            .scroll-wrap{
                height: calc(100vh - 180px);
                overflow-y: auto;
                .title{
                    text-align: center;
                    line-height: 36px;
                    color: #fff;
                    font-size: 15px;
                    background-color: #fb5966;
                }
                .sm-title{
                    font-size: 14px;
                    color: #333;
                    line-height: 30px;
                }
                .active{
                    // background-color: #fb5966;
                    color: #fb5966;
                }
            }
        }
        .list-right{
            width: calc(100% - 90px);
            overflow: hidden;
            .scroll-wrap{
                height: calc(100vh - 180px);
                overflow-y: auto;
                .right_title{
                    color: #fb5966;
                    padding: 10px;
                    font-size: 15px;
                    font-weight: 700;
                }
                .right-content{
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: space-between;
                    box-sizing: border-box;
                    padding: 0px 10px 5px 10px;
                    .right-item{
                        width: calc((100% - 10px)/2);
                        display: flex;
                        flex-direction: column;
                        .item-name{
                            font-size: 14px;
                            display: inline-block;
                            padding: 10px 0px;
                            overflow:hidden;
                            text-overflow:ellipsis;
                            white-space:nowrap;
                        }
                        .item-img{
                            border-radius: 6px;
                            width: 100%;
                            height: 120px;
                        }
                    }
                }
            }
            ::-webkit-scrollbar {
                width: 5px;
                height: 5px;
                background: #c1c1c1;
                background-color: rgba(239, 243, 248, 0.9);   //滚动框颜色
            }
            ::-webkit-scrollbar-track   {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                border-radius: 10px;
                background-color: #f5f5f5;
            }
            ::-webkit-scrollbar-thumb {
                border-radius: 10px;
                -webkit-box-shadow: inset 0 0 6px rgba(239, 243, 248, 0.3);   //滚动条颜色
                background-color: #c5c8cd;
            }
            ::-webkit-scrollbar-thumb:hover {
                border-radius: 5px;
                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: rgba(0, 0, 0, 0.1);
            }  
        }
    }
  }
  .activePoster {
    border: 1px solid rgb(103, 155, 252) !important;
  }
}
.scroll-wrap{
    .el-collapse-item__header{
        display: inline-block !important;
        height: 34px !important;
        line-height: 34px !important;
        font-size: 14px;
        width: 100%;
        background: #fb5966;
        color: #fff;
    }
    .el-collapse-item__arrow{
        display: none !important;
    }
    .el-collapse-item__content{
        padding-bottom: 0;
    }  
}

</style>